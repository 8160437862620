<template>
  <div class="card-table-header">
    <CustomCheckbox
      v-if="isCheckboxShown"
      :isCheckboxChecked="isCheckboxChecked"
      @click="handleCheckboxClick"
    />
    <div
      class="table-header-field editable-field-title"
      :class="{ 'active-state': order.includes('name') }"
      @click="handleOrderChange('name')"
      :style="{ paddingLeft: '40px' }"
    >
      Name
      <div class="sorting-arrows" v-if="!isNameSortArrowHiddent">
        <div
          class="arrow-up"
          :class="{ 'active-arrow-up': order === '-name' }"
        ></div>
        <div
          class="arrow-down"
          :class="{ 'active-arrow-down': order === 'name' }"
        ></div>
      </div>
    </div>
    <div
      v-if="isCompanyShown"
      class="table-header-field editable-field-title"
      :class="{ 'active-state': order.includes('company') }"
      @click="handleOrderChange('company')"
    >
      Company
      <div class="sorting-arrows" v-if="!isCompanySortArrowHiddent">
        <div
          class="arrow-up"
          :class="{ 'active-arrow-up': order === '-company' }"
        ></div>
        <div
          class="arrow-down"
          :class="{ 'active-arrow-down': order === 'company' }"
        ></div>
      </div>
    </div>
    <div
      v-if="isValueShown"
      class="table-header-field editable-field-title table-header-value-field"
      :class="{ 'active-state': order.includes('value') }"
      @click="handleOrderChange('value')"
    >
      Value
      <div class="sorting-arrows" v-if="!isValueSortArrowHiddent">
        <div
          class="arrow-up"
          :class="{ 'active-arrow-up': order === '-value' }"
        ></div>
        <div
          class="arrow-down"
          :class="{ 'active-arrow-down': order === 'value' }"
        ></div>
      </div>
    </div>
    <div
      v-if="isClosedDateShown"
      class="table-header-field editable-field-title"
    >
      Closed Date
    </div>
    <div v-if="prospectStatuses" class="prospect-status-headers">
      <div
        class="prospect-status-header"
        :class="{
          'active-state': order.includes(`status_${value}`),
          'common-cursor': value === 0,
        }"
        v-for="(value, key) in prospectStatuses"
        @click="handleOrderChange(`status_${value}`)"
        :key="key"
      >
        <div>{{ key }}</div>
        <div class="sorting-arrows" v-if="value !== 0">
          <div
            class="arrow-up"
            :class="{ 'active-arrow-up': order === `-status_${value}` }"
          ></div>
          <div
            class="arrow-down"
            :class="{ 'active-arrow-down': order === `status_${value}` }"
          ></div>
        </div>
      </div>
    </div>
    <div
      v-if="isExpirationDateShown"
      class="table-header-field editable-field-title"
    >
      Expiration Date
    </div>
    <div
      v-if="isMembersStatusShown"
      class="table-header-field editable-field-title"
    >
      Status
    </div>
    <div
      v-if="isMemberLastLoginShown"
      class="table-header-field editable-field-title"
    >
      Last login
    </div>
    <div
      v-if="isMemberPipelineValueShown"
      class="table-header-field editable-field-title"
    >
      Pipeline Value
    </div>
    <div
      v-if="isMemberMonthlyRevenueGoalShown"
      class="table-header-field editable-field-title"
    >
      Monthly Revenue Goal
    </div>
    <div
      v-if="isMemberMonthlyRevenueGoalShown"
      class="table-header-field editable-field-title"
    >
      Confidence Level
    </div>
    <div
      v-if="isMemberMonthlyRevenueGoalShown"
      class="table-header-field editable-field-title"
    >
      Non Revenue Goal
    </div>
    <div
      v-if="isMemberLastLoginShown && !hideMemberRole"
      class="table-header-field editable-field-title"
    >
      Role
    </div>
    <div
      v-if="isMemberLastLoginShown && !hideMemberCompany"
      class="table-header-field editable-field-title"
    >
      Company
    </div>
    <div
      v-if="isBillingDateShown"
      class="table-header-field editable-field-title"
    >
      Date
    </div>
    <div
      v-if="isBillingAmountShown"
      class="table-header-field editable-field-title"
    >
      Amount
    </div>
    <div
      v-if="isNoteShown"
      class="table-header-field editable-field-title note-field"
    >
      Note
    </div>
    <div
      v-if="isNotInPipelineValue"
      class="table-header-field editable-field-title"
    ></div>
    <div class="actions-header-field-absolute-wrapper">
      <div
        v-if="!isActionHeaderHiddent"
        class="table-header-field actions-header-field"
      >
        Action
      </div>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from '../CustomCheckbox.vue'

export default {
  props: [
    'isCheckboxShown',
    'isExpirationDateShown',
    'isCompanyShown',
    'isMembersStatusShown',
    'isMemberLastLoginShown',
    'isMemberPipelineValueShown',
    'isMemberMonthlyRevenueGoalShown',
    'isBillingDateShown',
    'isValueShown',
    'isNotInPipelineValue',
    'isBillingAmountShown',
    'isClosedDealArrowHidden',
    'isMemberRoleShown',
    'selectedRows',
    'prospectStatuses',
    'isClosedDateShown',
    'isNameSortArrowHiddent',
    'isCompanySortArrowHiddent',
    'isValueSortArrowHiddent',
    'isActionHeaderHiddent',
    'isSelectAllCheckbox',
    'hideMemberRole',
    'hideMemberCompany',
    'order',
    'isNoteShown',
  ],
  components: {
    CustomCheckbox,
  },
  data() {
    return {
      isCheckboxChecked: false,
    }
  },
  watch: {
    isSelectAllCheckbox(newValue, _) {
      if (newValue === false) {
        this.isCheckboxChecked = false
      } else if (newValue === true) {
        this.isCheckboxChecked = true
      }
    },
    selectedRows(newValue, _) {
      if (!newValue || !newValue.length) this.isCheckboxChecked = false
    },
  },
  methods: {
    handleCheckboxClick() {
      if (this.isCheckboxChecked) {
        this.isCheckboxChecked = false
        this.$emit('removeSelection')
      } else {
        this.isCheckboxChecked = true
        this.$emit('checkboxSelect')
      }
    },
    handleOrderChange(newOrder) {
      if (newOrder === 'status_0') return

      if (this.order.includes('status_') && this.order === newOrder) {
        this.$emit('setOrder', '')
        return
      }

      if (this.order === newOrder) this.$emit('setOrder', `-${newOrder}`)
      else if (this.order === `-${newOrder}`) this.$emit('setOrder', '')
      else this.$emit('setOrder', newOrder)
    },
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.card-table-header {
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  font-size: $fontSizeMinified;
  color: $fontColorGray;
  position: relative;

  .actions-header-field-absolute-wrapper {
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    padding-right: 40px;
    padding-left: 20px;
  }

  .table-header-field {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    cursor: pointer;
  }

  .editable-field-title {
    width: 150px;
    min-width: 150px;
  }

  .table-header-value-field {
    width: 80px;
    min-width: 80px;
  }

  .note-field {
    min-width: 350px;
    width: 100%;
    margin-left: 30px !important;
  }

  .prospect-status-headers {
    width: 328px;
    margin: 0 30px;
    display: flex;
    justify-content: space-between;

    .prospect-status-header {
      width: 42px;
      margin: 0 20px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .common-cursor {
      cursor: default;
    }
  }

  .active-state {
    color: $appActionColor;
  }

  .actions-header-field {
    width: 42px;
    justify-content: center;
  }

  div:last-of-type {
    margin-left: auto;
  }
}

@media (max-width: 420px) {
  .card-table-header {
    width: calc(100% - 80px);
  }
}

.sorting-arrows {
  width: 10px;
  height: 20px;
  margin-left: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .arrow-up {
    width: 10px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-image: url('../../../assets/icons/sorting-arrow-up-icon.svg');
  }

  .arrow-down {
    width: 10px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-image: url('../../../assets/icons/sorting-arrow-down-icon.svg');
  }

  .active-arrow-up {
    background-image: url('../../../assets/icons/sorting-arrow-up-active-icon.svg');
  }

  .active-arrow-down {
    background-image: url('../../../assets/icons/sorting-arrow-down-active-icon.svg');
  }
}
</style>
